<template>
  <div class="header">
    <div v-if="headerContent" class="row d-flex align-items-center">
      <div class="col-12 col-xl-6 text-center header-text-section">
        <p class="title-hero title-cs-xxl mb-0">
          {{ headerContent[0]?.title }}
          <span class="hell">{{ headerContent[0]?.title }}</span>
        </p>
        <h1
          v-if="headerContent[0]?.message1 && headerContent[0]?.message2"
          class="display-8 mb-5 hell-second"
        >
          {{ headerContent[0]?.message1 }}
          <br />
          {{ headerContent[0]?.message2 }}
        </h1>
      </div>
      <div class="col-12 col-xl-6 text-center header-image-section">
        <img
          :src="filePicture"
          alt="Benoit Petit"
          class="img-fluid rounded-circle shadow-lg"
          data-aos="zoom-out"
          height="350"
          width="350"
          title="Benoit Petit"
        />
      </div>
    </div>
    <div v-else class="smooth">
      <p class="text-light text-center mt-3">
        Les données sont en cours de chargement ...
      </p>
      <p class="text-light text-center">
        Si rien ne s'affiche actualiser la page 👍️
      </p>
      <div class="d-flex justify-content-center mt-3">
        <SpinnerComponent />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SpinnerComponent from "./SpinnerComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    SpinnerComponent,
  },
  data() {
    return {
      filePicture: require("@/assets/images/profil/pic.png"),
    };
  },
  computed: {
    ...mapState({
      headerContent: (state) => state.headerContent,
    }),
  },
};
</script>

<style scoped>
/* customs styles */
.header {
  padding-top: 5%;
}

.title-cs-xxl {
  font-size: 9.5rem;
  font-weight: bold;
  color: var(--foreground);
}

.hell {
  position: absolute;
  top: 100px;
  left: -100px;
  opacity: 0.02;
  font-size: 20rem;
}

@media screen and (max-width: 600px) {
  .hell {
    display: none;
  }

  .title-cs-xxl {
    font-size: 6rem;
    text-align: center;
  }

  .hell-second {
    text-align: center;
  }
}
</style>
