<template>
  <div data-aos="fade-zoom" data-aos-delay="100">
    <!-- Vérification de l'existence et de la longueur de blogContent -->
    <div v-if="blogContent && blogContent.length" class="posts row mt-3">
      <AsyncPostCard
        v-for="post in blogContent"
        :key="post.slug"
        :post="post"
      />
    </div>

    <!-- Spinner pendant le chargement -->
    <div v-if="isLoading" class="text-center mt-4">
      <SpinnerComponent />
    </div>

    <!-- Bouton Charger plus avec un spinner -->
    <div v-if="hasMorePosts && !isLoading" class="text-center mt-4">
      <button
        @click="$emit('load-more')"
        type="button"
        class="btn btn-load-more"
        :disabled="isLoading"
      >
        Charger plus
        <i class="bi bi-arrow-repeat"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import SpinnerComponent from "./SpinnerComponent.vue";

export default {
  name: "BlogContentComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasMorePosts: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["load-more"],
  components: {
    AsyncPostCard: defineAsyncComponent(() => import("./AsyncPostCard.vue")),
    SpinnerComponent,
  },
  setup() {
    const store = useStore();
    const blogContent = computed(() => store.state.blogContent || []);

    return {
      blogContent,
    };
  },
};
</script>

<style scoped>
.btn-load-more {
  background-color: var(--purple);
  border: none;
  color: var(--foreground);
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-load-more:hover {
  background-color: var(--pink);
  transform: translateY(-2px);
}

.btn-load-more:disabled {
  background-color: var(--selection);
  cursor: not-allowed;
}

.btn-load-more:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}
</style>
