import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

// Configuration de vuex-persist pour persister l'état dans localStorage
new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    projectsContent: state.projectsContent,
    aboutContent: state.aboutContent,
    aboutContent2: state.aboutContent2,
    experiencesContent: state.experiencesContent,
    // degreesContent: state.degreesContent,
    blogContent: state.blogContent,
    postsCache: state.postsCache,
    headerContent: state.headerContent,
    analytics: state.analytics,
  }),
});

export default createStore({
  state: {
    projectsContent: "",
    aboutContent: "",
    aboutContent2: "",
    experiencesContent: "",
    // degreesContent: "",
    blogContent: [],
    postsCache: {},
    headerContent: "",
    analytics: false,
    randomCharacterQuote: null,
    currentPage: 1,
    hasMorePosts: true,
  },
  getters: {
    projectsContent: (state) => state.projectsContent,
    aboutContent: (state) => state.aboutContent,
    aboutContent2: (state) => state.aboutContent2,
    experiencesContent: (state) => state.experiencesContent,
    // degreesContent: (state) => state.degreesContent,
    blogContent: (state) => state.blogContent,
    getPostBySlug: (state) => (slug) => state.postsCache[slug],
    headerContent: (state) => state.headerContent,
    randomCharacterQuote: (state) => state.randomCharacterQuote,
    currentPost: (state) => state.currentPost,
  },
  mutations: {
    ADD_BLOG_CONTENT(state, posts) {
      // Ajouter seulement les articles qui ne sont pas déjà présents
      const slugs = state.blogContent.map((post) => post.slug);
      const newPosts = posts.filter((post) => !slugs.includes(post.slug));
      state.blogContent = [...state.blogContent, ...newPosts];
    },
    // Si vous souhaitez remplacer complètement le contenu du blog, vous pouvez avoir une autre mutation :
    SET_BLOG_CONTENT(state, posts) {
      state.blogContent = posts;
    },
    SET_PROJECTS(state, projectsContent) {
      state.projectsContent = projectsContent;
    },
    SET_ABOUT(state, aboutContent) {
      state.aboutContent = aboutContent;
    },
    SET_ABOUT2(state, aboutContent2) {
      state.aboutContent2 = aboutContent2;
    },
    SET_EXPERIENCE(state, experiencesContent) {
      state.experiencesContent = experiencesContent;
    },
    // SET_DEGREES(state, degreesContent) {
    //   state.degreesContent = degreesContent;
    // },
    SET_BLOG(state, content) {
      state.blogContent = content;
    },
    APPEND_BLOG(state, content) {
      state.blogContent = [...state.blogContent, ...content];
    },
    SET_PAGE_SIZE(state, size) {
      state.pageSize = size;
    },
    SET_HEADER(state, headerContent) {
      state.headerContent = headerContent;
    },
    setAnalytics(state, value) {
      state.analytics = value;
    },
    SET_RANDOM_CHARACTER_QUOTE(state, quote) {
      state.randomCharacterQuote = quote;
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_CURRENT_POST(state, post) {
      state.currentPost = post;
    },
    SET_HAS_MORE_POSTS(state, value) {
      state.hasMorePosts = value;
    },
    INCREMENT_PAGE(state) {
      state.currentPage++;
    },
    SET_POST_CACHE(state, { slug, post }) {
      state.postsCache[slug] = post;
    },
  },
  actions: {
    async getProjectsContent({ state, commit }) {
      if (!state.projectsContent.length) {
        try {
          const response = await fetch("/.netlify/functions/projectsContent");
          const data = await response.json();
          commit("SET_PROJECTS", data.projectsContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAboutContent2({ state, commit }) {
      if (!state.aboutContent2.length) {
        try {
          const response = await fetch("/.netlify/functions/aboutContent2");
          const data = await response.json();
          commit("SET_ABOUT2", data.aboutContent2.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAboutContent({ state, commit }) {
      if (!state.aboutContent.length) {
        try {
          const response = await fetch("/.netlify/functions/aboutContent1");
          const data = await response.json();
          commit("SET_ABOUT", data.aboutContent1.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getExperiencesContent({ state, commit }) {
      if (!state.experiencesContent.length) {
        try {
          const response = await fetch(
            "/.netlify/functions/experiencesContent"
          );
          const data = await response.json();
          commit("SET_EXPERIENCE", data.experiencesContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    // async getDegreesContent({ state, commit }) {
    //   if (!state.degreesContent.length) {
    //     try {
    //       const response = await fetch("/.netlify/functions/degreesContent");
    //       const data = await response.json();
    //       commit("SET_DEGREES", data.degreesContent.results.reverse());
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    // },
    async getHeaderContent({ state, commit }) {
      if (!state.headerContent.length) {
        try {
          const response = await fetch("/.netlify/functions/headerContent");
          const data = await response.json();
          commit("SET_HEADER", data.headerContent.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async fetchRandomCharacterQuote({ state, commit }) {
      if (!state.randomCharacterQuote) {
        try {
          const response = await fetch("/.netlify/functions/quoteContent");
          const data = await response.json();
          commit("SET_RANDOM_CHARACTER_QUOTE", data.quoteContent);
        } catch (error) {
          console.error("Error fetching random character quote:", error);
        }
      }
    },
    async getBlogContent({ commit, state }, { reset = false }) {
      const page = reset ? 1 : state.currentPage + 1; // Si reset est vrai, on commence à la première page
      const pageSize = 8;

      try {
        const response = await fetch(
          `/.netlify/functions/blogContent?page=${page}&size=${pageSize}`
        );
        const data = await response.json();

        if (reset) {
          commit("SET_BLOG_CONTENT", data.blogContent); // Réinitialiser la liste
        } else {
          commit("ADD_BLOG_CONTENT", data.blogContent); // Ajouter les nouveaux articles
        }

        // Mettre à jour le numéro de page et indiquer s'il reste encore des articles à charger
        commit("SET_CURRENT_PAGE", data.currentPage);
        commit("SET_HAS_MORE_POSTS", data.blogContent.length === pageSize);
      } catch (error) {
        console.error("Erreur lors du chargement des articles:", error);
      }
    },
    async fetchSinglePost({ commit, state }, slug) {
      // Vérifier si l'article est déjà en cache
      if (state.postsCache[slug]) {
        return state.postsCache[slug];
      }

      try {
        const response = await fetch(
          `/.netlify/functions/singlePost?slug=${slug}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const post = await response.json();
        commit("SET_POST_CACHE", { slug, post });
        return post;
      } catch (error) {
        console.error("Error fetching single post:", error);
        throw error;
      }
    },
  },
  modules: {},
  // plugins: [vuexLocal.plugin], // Activation du plugin vuex-persist
});
